<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
    <card-and-graphs v-if="graphLoader" ref="myGraphs"></card-and-graphs>
    <br /><br />
    <v-card flat class="card-datatable">
      <v-card-text>
        <!--<v-row>
          <v-col cols="6" md="3" class="pl-3">
            <v-autocomplete
              label="Filter By Year"
              outlined
              dense
              v-model="yearFilter"
              :items="yearList"
              class="formFields"
              color="#7253CF"
              :disabled="loading"
              @change="getDonationData()"
            >
            </v-autocomplete>
          </v-col>
        </v-row>-->
        <v-row no-gutters>
          <v-col class="d-flex" cols="11" sm="4">
            <v-text-field
              outlined
              dense
              v-model="donationDataTable[$API_KEYS.SEARCH]"
              label="Search by Donor Name, Donor Email, Donation for School or District"
              append-icon="mdi-magnify"
              type="text"
              color="#38227A"
              class="formFields"
              height="43px"
            >
              <template v-slot:label>
                <div style="font-size: 15px">
                  Search by Donor Name, Donor Email, Donation for School or
                  District
                </div>
              </template>
            </v-text-field>
          </v-col>
          <!--<v-col
            v-if="$vuetify.breakpoint.xsOnly"
            class=""
            align-self="center"
            cols="1"
          >
            
          </v-col> -->
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <v-col class="d-flex justify-end text-right" cols="9" md="3" xl="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :disabled="donationDataTable.formLoading"
                  v-model="dateRangeText"
                  label="Filter by Date"
                  prepend-inner-icon="mdi-calendar-blank"
                  height="43px"
                  clearable
                  readonly
                  v-bind="attrs"
                  outlined
                  v-on="on"
                  dense
                  color="#2C1963"
                  style="font-size: 15px"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                range
                no-title
                @input="filterDate(dates)"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="2"
            class="text-center"
            v-if="
              layout === 'CampaignDashboardLayout' &&
              $vuetify.breakpoint.smAndUp &&
              (this.$route.matched[0].path.substring(1) === 'district' ||
                this.$route.matched[0].path.substring(1) === 'school')
            "
          >
            <v-btn
              color="#38227A"
              dark
              @click="
                toggleAddEditViewDonationModal({ show: true, type: 'add' })
              "
              class="px-7 text-capitalize"
              height="43px"
              style="border-radius: 10px"
            >
              <span>Add Donation</span>
            </v-btn>
          </v-col>
          <v-menu
            offset-y
            v-if="
              layout === 'CampaignDashboardLayout' &&
              $vuetify.breakpoint.xsOnly &&
              (this.$route.matched[0].path.substring(1) === 'district' ||
                this.$route.matched[0].path.substring(1) === 'school')
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
                >mdi-menu</v-icon
              >
            </template>
            <v-list>
              <v-list-item-group active-class="active-class">
                <v-list-item
                  @click="
                    toggleAddEditViewDonationModal({ show: true, type: 'add' })
                  "
                >
                  <v-list-item-title> Add Donation </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-row>
      </v-card-text>
    </v-card>
    <div class="table-div">
      <v-data-table
        fixed-header
        :options.sync="dataOptions"
        hide-default-footer
        :headers="donationDataTable.headers"
        :items="donationDataTable.items"
        :loading="donationDataTable.loading"
        :items-per-page="
          donationDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :server-items-length="
          donationDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        loading-text="Loading... Please wait"
        class="elevation-1"
        mobile-breakpoint="0"
      >
        <template v-slot:item="row">
          <tr class="text-flex" style="font-size: 14px">
            <td class="text-center py-4" style="font-weight: 600">
              <div style="width: 90px">{{ row.item.donation_date_str }}</div>
              <div>{{ row.item.donation_time_str }}</div>
            </td>
            <td class="text-center py-4" style="font-weight: 600">
              <div>{{ row.item.donor_name }}</div>
              <div style="font-size: 13px; color: rgba(29, 29, 29, 0.6)">
                {{ row.item.donor_email }}
              </div>
            </td>
            <td class="text-center py-4">
              <div>
                <v-chip small :class="`${row.item.payment_mode.toUpperCase()}`">
                  {{ row.item.payment_mode.toUpperCase() }}
                </v-chip>
                <br />
              </div>
              <div>${{ row.item.donated_amount }}</div>
            </td>
            <td class="text-center py-4" style="font-weight: 600">
              <div>
                {{ row.item.donation_for_name }}
              </div>
              <div style="font-size: 13px; color: rgba(29, 29, 29, 0.6)">
                ({{ row.item.donation_for }})
              </div>
            </td>
            <td class="text-center py-4" style="font-weight: 600">
              <div>{{ row.item.school_name }}</div>
              <div>
                {{ row.item.district_name }}
              </div>
              <div style="color: #777777; font-size: 13px">
                {{ row.item.campaign_id }}
              </div>
            </td>
            <td class="text-center py-4">
              <div style="font-weight: 600; color: #777777">
                {{ row.item.processing_fees }}
              </div>
            </td>
            <td class="text-center row-item py-4">
              <v-row class="justify-right">
                <v-col class="pa-0 text-center" cols="12" xl="8">
                  <v-chip
                    @click="
                      toggleAddEditViewDonationModal({
                        show: true,
                        id: row.item.id,
                        type: 'edit',
                        mode: row.item.payment_mode.toUpperCase(),
                      })
                    "
                    :class="`actionColumn-btn ${row.item.payment_mode.toUpperCase()}`"
                    color="#F2F2F2"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="22"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </v-chip>
                  <v-chip
                    v-if="row.item.payment_mode.toUpperCase() !== 'ONLINE'"
                    @click="
                      toggleDeleteDonationModal({
                        Delete: true,
                        id: row.item.id,
                      })
                    "
                    class="actionColumn-btn"
                    color="#F2F2F2"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dark
                          v-bind="attrs"
                          v-on="on"
                          class="icons"
                          color="#6B6B6B"
                          size="25"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </v-chip>
                </v-col>
              </v-row>
            </td>
          </tr>
        </template>
        <template
          v-slot:footer
          v-if="donationDataTable.items[0] && $vuetify.breakpoint.smAndUp"
        >
          <div>
            <v-divider></v-divider>
            <v-row no-gutters class="pt-6 px-2">
              <v-col
                class="mb-4"
                align-self="center"
                cols="12"
                xs="12"
                sm="6"
                md="4"
              >
                <div
                  class="justify-center justify-md-start d-flex px-5 tableHeader-text"
                >
                  <div class="alignSelf-center mr-3">Show</div>
                  <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                    <v-select
                      class=""
                      :items="itemsPerPage"
                      hide-details
                      v-model="
                        donationDataTable[$API_KEYS.PAGE_INFO][
                          $API_KEYS.ITEMS_PER_PAGE
                        ]
                      "
                      dense
                    ></v-select>
                  </div>

                  <div class="alignSelf-center">
                    {{
                      donationDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    }}
                    of
                    {{
                      donationDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    }}
                  </div>
                </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" xs="12" sm="4" md="4">
                <div>
                  <v-pagination
                    v-model="
                      donationDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.PAGE_NUMBER
                      ]
                    "
                    :length="
                      donationDataTable[$API_KEYS.PAGE_INFO][
                        $API_KEYS.TOTAL_PAGE
                      ]
                    "
                    :total-visible="5"
                    color="#D30024"
                    class="pagination"
                  >
                  </v-pagination>
                </div>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>
    </div>
    <v-card
      v-if="donationDataTable.items[0] && $vuetify.breakpoint.xsOnly"
      flat
      class="footer-card"
    >
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="justify-center justify-md-start d-flex px-5 tableHeader-text"
            >
              <div class="alignSelf-center mr-3">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPage"
                  hide-details
                  v-model="
                    donationDataTable[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  dense
                ></v-select>
              </div>

              <div class="alignSelf-center">
                {{
                  donationDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                }}
                of
                {{
                  donationDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="
                  donationDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                "
                :length="
                  donationDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                "
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <add-edit-donation-modal @reload="reloadMethod"></add-edit-donation-modal>
    <donation-delete-modal @reload="reloadMethod"></donation-delete-modal>
  </div>
</template>
<script>
import {
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
  DATA,
  DONATION_LIST,
} from "@/constants/APIKeys";
import moment from "moment";
import { ITEMS, LOADING } from "@/constants/ModuleKeys";
import {
  API_ADMIN_DONATION_LIST,
  API_GET_FISCAL_YEAR_RANGE,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
import { mapActions, mapGetters } from "vuex";
import authToken from "@/common/authToken";
import _ from "lodash";
export default {
  name: "DonationManagement",
  components: {
    CardAndGraphs: () => import("./CardAndGraphs/CardAndGraphs.vue"),
    AddEditDonationModal: () =>
      import("./AddEditDonationModal/AddEditDonationModal.vue"),
    DonationDeleteModal: () =>
      import("./DonationDeleteConfirmation/DonationDeleteConfirmation.vue"),
  },
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      yearList: [],
      yearFilter: "",
      dates: [],
      menu: false,
      dataOptions: { sortBy: [], sortDesc: [] },
      donationDataTable: {
        loading: false,
        headers: [
          {
            sortable: false,
            text: "Time/Date",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: false,
            text: "Donor Info",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: false,
            text: "Amount",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: false,
            text: "Donation For",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg", "px-0"],
          },
          {
            sortable: false,
            text: "Campaign",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg", "px-0"],
          },
          {
            sortable: false,
            text: "Processing Fees",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
          {
            width: "13%",
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: false,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      itemsPerPage: [10, 15, 20, 30, 50, 100],
      graphLoader: true,
    };
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    userTeam() {
      return authToken.decodedToken().user_team;
    },
    userType() {
      return authToken.decodedToken().user_type;
    },
    layout() {
      return this.$route.meta.layout;
    },
    dateRangeText: {
      get() {
        if (this.dates) {
          if (this.dates.length > 1) {
            let date = [
              moment(this.dates[0]).format("MM-DD-YYYY"),
              moment(this.dates[1]).format("MM-DD-YYYY"),
            ];
            return date.join(" - ");
          } else {
            return "";
          }
        } else {
          return "";
        }
        // return this.dates.join('  -  ')
      },
      set(newDates) {
        this.dates = newDates || [];
        return this.dates.length > 1 ? this.dates.join("  -  ") : "";
      },
    },
  },
  watch: {
    //Watcher for detecting change in PAGE_NUMBER
    "donationDataTable.page_info.page_number": function () {
      this.getDonationData();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "donationDataTable.page_info.detail_count": function () {
      this.getDonationData();
    },
    //Watcher for detecting change in SEARCH
    "donationDataTable.search": function () {
      this.debounceGetDonationManagementData();
    },
    //Watcher for detecting Change in filter by date
    dates: function () {
      if (this.dates.length !== 1) {
        this.debounceGetDonationManagementData();
      }
    },
  },
  methods: {
    ...mapActions({
      // teacher Modal
      toggleAddEditViewDonationModal: "donationManagement/toggleModal",
      toggleDeleteDonationModal: "donationManagement/toggleDeleteModal",
    }),
    filterDate(dates) {
      if (dates.length > 1) {
        this.$refs.menu.save(dates);
      }
    },
    reloadMethod() {
      this.graphLoader = false;
      this.getDonationData();
    },
    getDonationData() {
      const self = this;
      self.donationDataTable.loading = true;
      const successHandler = (res) => {
        self.donationDataTable.items = res[DATA][DONATION_LIST];
        self.donationDataTable[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.donationDataTable[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.donationDataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];

        this.graphLoader = true;
        self.donationDataTable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.donationDataTable.loading = false;
      };
      let dataTableParams = {};
      dataTableParams[SEARCH] = this.donationDataTable[SEARCH];
      if (this.donationDataTable[PAGE_INFO][ITEMS_PER_PAGE] == 0) {
        dataTableParams[ITEMS_PER_PAGE] = 10;
      } else {
        dataTableParams[ITEMS_PER_PAGE] =
          this.donationDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      }
      if (this.donationDataTable[PAGE_INFO][PAGE_NUMBER] == 0) {
        dataTableParams[PAGE_NUMBER] = 1;
      } else {
        dataTableParams[PAGE_NUMBER] =
          this.donationDataTable[PAGE_INFO][PAGE_NUMBER];
      }
      dataTableParams[SORTING_KEY] = "";
      if (this.yearFilter) {
        dataTableParams.fiscal_year = this.yearFilter;
      }
      if (this.dates) {
        if (this.dates.length == 2) {
          console.log("dates", this.dates);
          let filter = {
            start_date: moment(this.dates[0]).format("MM-DD-YYYY"),
            end_date: moment(this.dates[1]).format("MM-DD-YYYY"),
          };
          dataTableParams["filter"] = filter;
        }
      }
      // if(this.layout ==='CampaignDashboardLayout'){
      //   if(this.userTeam ==="district" ||this.userTeam ==="school"){
      //     dataTableParams["campaign_id"]= this.selectedCampaignData.campaignData.id
      //     console.log(this.selectedCampaignData.campaignData.id)
      //   }
      //   if(this.userTeam==="admin"){
      //     dataTableParams["campaign_id"] =
      //       this.selectedCampaignData.campaignData.id;
      //       if(this.$route.matched[0].path.substring(1)==="district"){
      //         dataTableParams["district_id"] = this.selectedCampaignData.organizationData.id;
      //       }else{
      //         dataTableParams["school_id"] = this.selectedCampaignData.organizationData.id;
      //       }
      //   }
      // }
      let PANEL = this.$route.matched[0].path.substring(1);
      if (this.layout === "CampaignDashboardLayout") {
        if (PANEL === "district" && this.userTeam === "district") {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
        } else if (PANEL === "school" && this.userTeam === "school") {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
        } else if (PANEL === "district" && this.userTeam === "admin") {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
          dataTableParams["district_id"] =
            this.selectedCampaignData.organizationData.id;
        } else {
          dataTableParams["campaign_id"] =
            this.selectedCampaignData.campaignData.id;
          dataTableParams["school_id"] =
            this.selectedCampaignData.organizationData.id;
        }
      }

      Axios.request_GET(
        API_ADMIN_DONATION_LIST,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getYearList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.yearList = data.year_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      return Axios.request_GET(
        API_GET_FISCAL_YEAR_RANGE,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
  },
  created() {
    this.$root.$refs.donationManagement = this;
    this.debounceGetDonationManagementData = _.debounce(
      this.getDonationData,
      500
    );
  },
  mounted() {
    this.getDonationData();
    this.getYearList();
  },
};
</script>
<style scoped>
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
}
.footer-card.v-card {
  max-height: 70px;
  font-family: Lato;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
}
.alignSelf-center {
  align-self: center;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.card-datatable.v-card {
  max-height: 150px;
  margin-bottom: -2px;
  display: flex;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
span.actionColumn-btn.v-chip.ONLINE {
  border-radius: 4px;
  margin-left: 50px;
}
span.actionColumn-btn.v-chip.CASH {
  border-radius: 4px;
}
span.actionColumn-btn.v-chip.CHECK {
  border-radius: 4px;
}

.tableHeader-text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
.v-icon.notranslate.mobile-menu {
  position: relative;
  bottom: 12px;
  right: -7px;
}
span.v-chip.CASH {
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 45px;
  font-weight: 700;
  font-size: 12px;
}
span.v-chip.CHECK {
  background-color: rgba(237, 109, 17, 0.2);
  color: #ed6d11;
  border-radius: 45px;
  font-weight: 700;
  font-size: 12px;
}
span.v-chip.ONLINE {
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 45px;
  font-weight: 700;
  font-size: 12px;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  border-radius: 0px;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 60px;
  }
  .table-div {
    overflow: scroll;
  }
  .v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
    min-width: 1200px;
  }
  .pagination {
    float: none;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .tableHeader-text {
    font-size: 12px !important;
  }
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
